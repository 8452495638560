import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';

import FadeIn from '../components/transitions/FadeIn';
import FadeInUp from '../components/transitions/FadeInUp';
import Layout from '../components/layout';
import SEO from '../components/seo';
import testimonials from '../constants/testimonials/testimonials';

const useStyles = makeStyles({
    avatar: {
        height: '60px',
        width: '60px',
    },
});

const Reviews = () => {
    const photoData = useStaticQuery(graphql`
        query {
            pokLogo: file(relativePath: { eq: "logos/testimonials/pok_logo.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FIXED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 80
                    )
                }
            }
            dcmLogo: file(relativePath: { eq: "logos/testimonials/dcm-logo.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FIXED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 80
                    )
                }
            }
            f3MarinaFlLogo: file(relativePath: { eq: "logos/testimonials/f3-marina-fl.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FIXED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 80
                    )
                }
            }
            hcLogo: file(relativePath: { eq: "logos/testimonials/hc-logo.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FIXED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 80
                    )
                }
            }
            btmLogo: file(relativePath: { eq: "logos/testimonials/baytowne-logo.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FIXED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 80
                    )
                }
            }
            rmLogo: file(relativePath: { eq: "logos/testimonials/russell-marine.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FIXED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 80
                    )
                }
            }
            sbcLogo: file(relativePath: { eq: "logos/testimonials/seattle-boat.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FIXED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 80
                    )
                }
            }
            milltownLogo: file(relativePath: { eq: "logos/testimonials/milltown.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FIXED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 80
                    )
                }
            }
            seabreezeLogo: file(relativePath: { eq: "logos/testimonials/seabreeze.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FIXED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 80
                    )
                }
            }
            gordysLogo: file(relativePath: { eq: "logos/testimonials/gordys.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FIXED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 80
                    )
                }
            }
            nautiLogo: file(relativePath: { eq: "logos/testimonials/nauti.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FIXED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 80
                    )
                }
            }
            riverEdgeLogo: file(relativePath: { eq: "logos/testimonials/riveredge.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FIXED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 80
                    )
                }
            }
            keelforceLogo: file(relativePath: { eq: "logos/testimonials/keelforce.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FIXED
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED,
                        width: 80
                    )
                }
            }
        }
    `);

    const classes = useStyles();
    return (
        <Layout>
            <SEO title="Reviews" description="Reviews and feedback from marina managers and employees who use SpeedyDock at their dry stack marina or boat club." />
            <Box pb={8} pt={16} bgcolor="secondary.dark" color="primary.main">
                <Container>
                    <FadeIn>
                        <Typography variant="h3" component="h1" gutterBottom>
                            Reviews
                        </Typography>
                    </FadeIn>
                </Container>
            </Box>
            <Box pb={8} pt={8}>
                <Container>
                        <Grid container spacing={4} justify="center">
                        {
                            testimonials.map(testimonial => (
                                <Grid item xs={12} md={6} key={`${testimonial.author}${testimonial.company}`}>
                                    <FadeInUp>
                                        <Card variant="outlined">
                                            <Box p={3}>
                                                <Box pb={2} display="flex" alignItems="center" justifyContent="space-between">
                                                    <Avatar className={classes.avatar}>
                                                        <FormatQuoteIcon fontSize="large" />
                                                    </Avatar>
                                                    {testimonial.imgName && (
                                                        <GatsbyImage className={classes.image} image={getImage(photoData[testimonial.imgName].childImageSharp)} alt={`${testimonial.company} logo`} width="80" />
                                                    )}
                                                </Box>
                                                <Typography>
                                                    {testimonial.quote}
                                                </Typography>
                                                <Box pt={2}>
                                                    <Typography variant="body2" color="primary">
                                                        {`— ${testimonial.author}, ${testimonial.company}`}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Card>
                                    </FadeInUp>
                                </Grid>
                            ))
                        }
                        </Grid>
                </Container>
            </Box>
        </Layout>
    );
};

export default Reviews;
