import Products from '../products/products';

const Testimonials = [
    {
        company: 'Boat Rentals of America',
        author: 'Kristin Breum - Operations Manager',
        products: [Products.BOAT_RENTAL.name],
        quote: 'I have no idea how I would have survived without this software for the last year. It has made managing the billing with my members so easy!'
    },
    {
        company: 'Port of Kimberling',
        author: 'Marina Manager',
        logoSrc: '/logos/testimonials/pok_logo.png',
        imgName: 'pokLogo',
        products: [Products.DRY_STACK.name],
        quote: 'In just one season, we were able to streamline our operations, both from an administrative perspective and on the ground with our forklift operators and managers. Our customers appreciate the convenience of being able to request their boat out on their schedule, instead of only during our office hours.',
    },
    {
        company: 'Deep Creek Marina',
        author: 'Manager',
        logoSrc: '/logos/testimonials/dcm-logo.png',
        imgName: 'dcmLogo',
        products: [Products.DRY_STACK.name],
        quote: 'We love using SpeedyDock. It has made launching boats from our high and dry storage a breeze! It has given us the ability to have customer request their boats out of high and dry themselves. These requests go directly to the person in charge of launching and it has eliminated miscommunications from the service advisor to the launcher.',
    },
    {
        company: 'Keowee Marina',
        author: 'Joe Dunsavage - Operations Manager',
        products: [Products.BOAT_CLUB.name, Products.BOAT_RENTAL.name],
        quote: `At Keowee Marina in Seneca, SC we run a full service marina – a large part of our business is rental boats during spring and (especially) summer.  We found ourselves tired of the lack of control, fees associated with processing and generally wanting to be able to provide a better / easier solution for our customers.  
                We were referred to Terry from Boat Club Software and immediately made the change.  We now havce total control over our rentals, the process itelf and did away with silly fees the booking site would charge our guests.
                Terry held our hands thru the entire process and helped us get setup and rolling much faster than we had anticipated.  Switching over to Boat Club Software was the best decision we could have made for our bottom line, back end and especially for our customers!`
    },
    {
        company: 'F3 Marina Fort Lauderdale',
        author: 'Marina Manager',
        logoSrc: '/logos/testimonials/f3-marina-fl.png',
        imgName: 'f3MarinaFlLogo',
        products: [Products.DRY_STACK.name],
        quote: 'SpeedyDock offers a great combination of ease and efficiency for our boaters to request their vessels, and also for our staff to manage the various launch reservations being made throughout the day.',
    },
    {
        company: 'Gulf Stream Boat Club',
        author: 'Alex Warner - Owner',
        products: [Products.BOAT_CLUB.name, Products.BOAT_RENTAL.name],
        quote: `I've been operating my boat club and rental platform for more than a decade and Boat Club Software has been a game changer.  Going from my original calendar system to this software felt daunting but it really just took a few days to get comfortable and now I can't imagine working without it. 
Terry understands my business, is very responsive and attentive so we are happy to continue with this platform. The software is very robust and has a ton of functionality to fit the needs of almost any rental/club platform. I think of Terry as a partner and appreciate him.`
    },
    {
        company: 'Harbour Cove Marina',
        author: 'Marina Manager',
        logoSrc: '/logos/testimonials/hc-logo.png',
        imgName: 'hcLogo',
        products: [Products.DRY_STACK.name],
        quote: 'SpeedyDock is great. It helped so much last year. The increased efficiency and turn around time savings from skipping the office and going to our drivers really improved our process. I don\'t know what I\'d do without it.',
    },
    {
        company: 'Baytowne Marina',
        author: 'Dockmaster',
        logoSrc: '/logos/testimonials/baytowne-logo.png',
        imgName: 'btmLogo',
        products: [Products.DRY_STACK.name],
        quote: 'Our marina had a very successful year last year and I attribute a lot of that success to this system. By sharing the system with multiple people at the marina and being able to access it on our phones, we were able to book boats more efficiently. I don\'t know what how we did without it before.',
    },
    {
        company: 'Russell Marine - Kowaliga',
        author: 'Office Manager',
        logoSrc: '/logos/testimonials/russell-marine.png',
        imgName: 'rmLogo',
        products: [Products.DRY_STACK.name],
        quote: 'Really can’t tell you how much we enjoy working with your program. It certainly did simplify our launching program for us.',
    },
    {
        company: 'Milltown Marina',
        author: 'Manager',
        logoSrc: '/logos/testimonials/milltown.png',
        imgName: 'milltownLogo',
        products: [Products.DRY_STACK.name],
        quote: 'Best APP ever and exceptional customer service.',
    },
    {
        company: 'Gordy\'s Marine',
        author: 'Marina Manager',
        logoSrc: '/logos/testimonials/gordys.png',
        imgName: 'gordysLogo',
        products: [Products.DRY_STACK.name],
        quote: 'SpeedyDock worked very well this season. We\'re happy with it and our customers really liked using the app.',
    },
    {
        company: 'Russell Marine - The Ridge',
        author: 'Office Manager',
        logoSrc: '/logos/testimonials/russell-marine.png',
        imgName: 'rmLogo',
        products: [Products.DRY_STACK.name],
        quote: 'We ABSOLUTELY love SpeedyDock!!!!!',
    },
    {
        company: 'Island Sailing',
        author: 'Stephen Frankland - Owner / Operator',
        products: [Products.BOAT_CLUB.name, Products.BOAT_RENTAL.name],
        quote: 'We were very fortunate to have found Boat Club for our sailing school and club. After exhausting just about every program out there only to find that while they do one or two aspects very well they consistently left crucial areas of management unaddressed. Boat club not only allows us to manage all aspects of our membership - billing, membership levels, upgrades, and member reservations for our growing membership but also allows us to manage our school side of the operations effectively. For all its effective functionality, what we really appreciate most about Boat Club is the incredible responsiveness and excellent customer service provided by its developer. Boat Club has been tirelessly working to evolve as well as to respond to our growing needs and our choice to go with the program to manage our club and school has been one of the best things we could have done for our business!'
    },
    {
        company: 'Nauti-Club',
        author: 'Manager',
        logoSrc: '/logos/testimonials/nauti.png',
        imgName: 'nautiLogo',
        products: [Products.BOAT_CLUB.name],
        quote: 'When we started searching for a solution for our club we struggled to find something that fit even 50% of our needs. SpeedyDock covers 99% of what we were looking to do with our boat club and we are very happy with it.',
    },
    {
        company: 'Riveredge Marina',
        author: 'Office Manager',
        logoSrc: '/logos/testimonials/riveredge.png',
        imgName: 'riverEdgeLogo',
        products: [Products.DRY_STACK.name],
        quote: 'Our employees picked up on the system quickly and I have heard nothing but positive comments about SpeedyDock from our customers. It’s been great to get started with this season!',
    },
    {
        company: 'Keelforce',
        author: 'Owner',
        logoSrc: '/logos/testimonials/keelforce.png',
        imgName: 'keelforceLogo',
        products: [Products.DRY_STACK.name],
        quote: 'It\'s the ticket! It\'s easy to use and people like it.',
    }
];

export default Testimonials;